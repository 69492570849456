import { Formik } from 'formik'
import { Button, Grid, makeStyles, Paper, Box, Typography } from '@material-ui/core'
import { navigate } from 'gatsby'
import MyTextField from '../components/forms/MyTextField'
import React from 'react'
import Layout from '../components/layout'
import axios from 'axios'
import MyRadio from '../components/forms/MyRadio'
import * as yup from 'yup'
import OrderForm from '../components/forms/OrderForm'
import SEO from '../components/seo'





const useStyles = makeStyles({
    root: {
        maxWidth:'20rem',
        margin: 'auto',
        textAlign:'center',
        padding: '1rem'
    },
    bg: {
        minHeight:"100%",
        height:'100%',
        paddingTop:'5rem',
        paddingBottom: '5rem'
    },
    title: {
        fontSize: 50
    }
})

const OrderPage = ({location}) => {
    const classes = useStyles()
    console.log(process.env.GATSBY_API_URL)
    return (
        <Layout>
            <SEO title='Bestilling'/>
            <Box bgcolor="primary.main" className={classes.bg}>
            <Paper className={classes.root}>
            <Typography className={classes.title}>Bestill minilager</Typography>
            <OrderForm choice = {location.state ? location.state.choice: undefined} />
            </Paper>
            </Box>
        </Layout>)
}

export default OrderPage
