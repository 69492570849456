import { Field, Formik } from 'formik'
import { Box, Button, Checkbox, FormControlLabel, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { navigate } from 'gatsby'
import MyTextField from './MyTextField'
import MyRadio from './MyRadio'
import OrderDatePicker from './OrderDatePicker'
import React from 'react'
import axios from 'axios'
import * as yup from 'yup'
import MyCheckBox from './MyCheckBox'
import SizeSelectionArea from './SizeSelectionArea'


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const postalnrRegExp = /^[0-9]{4}/
const validationSchema = yup.object({
    firstName: yup.string().required('Dette feltet er påkrevd.').max(50, 'Fornavn kan maksimalt være 50 tegn.'),
    lastName: yup.string().required('Dette feltet er påkrevd.').max(50, 'Etternavn kan maksimalt være 50 tegn.'),
    address: yup.string().required('Dette feltet er påkrevd.').max(50, 'Adresse kan maksimalt være 50 tegn.'),
    postalnr: yup.string().required('Dette feltet er påkrevd.').matches(postalnrRegExp, 'Postnummeret er ugyldig').test('len', 'Postnummeret er ugyldig', val => val !== undefined && val.length === 4),
    postalloc: yup.string().required('Dette feltet er påkrevd.').max(50, 'Poststed kan maksimalt være 50 tegn'),
    eMail: yup.string().required('Dette feltet er påkrevd.').email('E-postadressen er ugyldig.'),
    phoneNumber: yup.string().required('Dette feltet er påkrevd.').matches(phoneRegExp, 'Telefonnummeret er ugyldig'),
    size: yup.string().required('Du må velge en type minilager'),
    consentsToTerms: yup.boolean().isTrue('Du må akseptere avtalevilkårene')
})

const OrderForm = ({ choice }) => {
    const [unspecifiedError, setUnspecifiedError] = React.useState<boolean>(false)
    const a = 6
    const b = 12
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
      };


    return (
        <Formik initialValues={{ firstName: "", lastName: '', address: '', postalnr: '', postalloc: '', eMail: '', phoneNumber: '', size: undefined, startDate: Date(), consentsToTerms: false}}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true)
                console.log(data)

                try {
                    const res = await axios.post(process.env.GATSBY_API_URL + '/order', data)
                    navigate('/ordersuccess')
                } catch (error) {
                    setUnspecifiedError(true)
                }

                setSubmitting(false)
            }}
            validationSchema={validationSchema}>
            {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>



                    <Grid container justify="space-around" spacing={3}>
                        <Grid item xs={b} justify="space-around" container>
                            <SizeSelectionArea choice/>
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                            {values.size?
                            <Typography>Ditt lager: {values.size}m<sup>2</sup></Typography>
                        : <Typography>Ingen størrelse valgt</Typography>}
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                            <MyTextField label="Fornavn" name="firstName" type="input" variant="filled" />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                            <MyTextField label="Etternavn" name="lastName" type="input" variant="filled" />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                            <MyTextField label="Adresse" name="address" type="input" variant="filled" />
                        </Grid>

                        <Grid item xs={b} justify="space-around" container>

                            <MyTextField label="Postnummer" name="postalnr" type="input" variant="filled" />
                        </Grid>

                        <Grid item xs={b} justify="space-around" container>

                            <MyTextField label="Poststed" name="postalloc" type="input" variant="filled" />
                        </Grid>

                        <Grid item xs={b} justify="space-around" container>

                            <MyTextField label="E-post" name="eMail" type="input" variant="filled" />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>

                            <MyTextField label="Telefon" name="phoneNumber" type="input" variant="filled" />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>

                        </Grid>

                        <Grid item xs={b} justify="space-around" container>
                            <OrderDatePicker label='Ønsket startdato' value={values.startDate} onChange={value => setFieldValue("startDate", value)} />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                            <MyCheckBox label="terms" name="consentsToTerms" />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                            <a href={'../../Melhus minilager LEIEKONTRAKT.pdf'} download> Last ned vilkår </a>
                        </Grid>

                        <Grid item xs={b} justify="space-around" container>
                            <Button variant='contained' color='primary' type="Send" disabled={isSubmitting}>Bestill lager</Button>
                        </Grid>

                        <Grid item xs={b} justify="space-around" container>
                            {unspecifiedError ? <Alert severity="error">Det ser ut som noe gikk galt</Alert> : ""}
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>)
}

export default OrderForm