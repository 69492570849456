import { FieldAttributes, useField } from "formik"
import { Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core"
import Typography from '@material-ui/core/Typography'
import React from "react"

const MyCheckBox: React.FC<FieldAttributes<{}>> = (props) => {
    const [field, meta] = useField<{}>(props)
    const errorText = meta.error && meta.touched ? meta.error: ''

    return (
        <div>
        <FormControlLabel
        control = {<Checkbox {...field} {...props} />}
        label={<>
            <Typography>Jeg aksepterer</Typography>
            <Typography>avtalevilkårene</Typography>
            </>}
        />
        
        
        {errorText?<FormHelperText error>{errorText}</FormHelperText>:''}
        </div>
    )
}

export default MyCheckBox