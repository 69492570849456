import { Box, Grid, makeStyles, Paper, Tab, Tabs, FormHelperText} from '@material-ui/core';
import { FieldAttributes, useField } from "formik"
import React from 'react'
import MyRadio from './MyRadio';

const useStyles = makeStyles({
    tab:{
        minHeight:1000,
        backgroundColor:'red'
    },
    sizeCard:{
        backgroundColor:'#F26718',
        minWidth: '120px',
        color:'white',
        padding:'0.5rem',
        margin:'0.5rem'
    }
})


function TabPanel(props: any) {
  
    return (
      <div
        role="tabpanel"
        hidden={props.value !== props.index}
        id={`simple-tabpanel-${props.index}`}
        aria-labelledby={`simple-tab-${props.index}`}
        {...props.other}
      >
        {props.value === props.index && (
              <Grid container>
              {props.children}
              </Grid>
        )}
      </div>
    );
  }

const storageUnits = {
    XS:[
        {
            price:'359',
            area:'2.6',
            volume:'6.5'
        }
    ],
    S:[
        {
            price:'550',
            area:'4',
            volume: '10'
        },
        {
            price:'689',
            area:'5',
            volume: '12,5'
        }

    ],
    M:[
        {
            price:'770',
            area:'5,5',
            volume: '14'
        },
        {
            price:'825',
            area:'6,1',
            volume: '15'
        }
    ],
    L:[]
}


interface StorageUnitSelectableDisplayProps {
    price: string,
    area: string,
    volume: string,
    className: any
}


const StorageUnitSelectableDisplay =(props:StorageUnitSelectableDisplayProps) =>{

    return (
        <Grid item xs={6}>
            <Paper className={props.className}>
            <div>{props.area} m<sup>2</sup></div>
            <div>{props.volume} m<sup>3</sup></div>
            <div>{props.price},-/mnd</div>
            <MyRadio name='size' type='radio' labelPlacement='left' label="Velg" value={props.area}/>
            </Paper>
        </Grid>
    )
}


const SizeSelectionArea: React.FC<FieldAttributes<{}>> = (props) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    };
    const [field, meta] = useField<{}>(props)
    const errorText = meta.error && meta.touched ? meta.error: ''
    
    return (
        <>
        <Tabs value={value} onChange={handleChange} variant='fullWidth'>
        <Tab label = 'XS' style={{minWidth:'75px'}}/>
        <Tab label = 'S' style={{minWidth:'75px'}}/>
        <Tab label = 'M' style={{minWidth:'75px'}}/>
        <Tab label = 'L' style={{minWidth:'75px'}}/>
    </Tabs>
    <TabPanel index={0} value={value} className={classes.tab}>
        {
        storageUnits.XS.map((unit) => {
            return(
            <StorageUnitSelectableDisplay className={classes.sizeCard} key={unit.area} {...unit}/>
            )
        })
    }
    </TabPanel>
    <TabPanel index={1} value={value}>
        <Grid container>
        {
        storageUnits.S.map((unit) => {
            return(
            <StorageUnitSelectableDisplay className={classes.sizeCard} key={unit.area} {...unit}/>
            )
        })
        
    }</Grid>
    </TabPanel>
    <TabPanel index={2} value={value}>
    <Grid container>
        {
        storageUnits.M.map((unit) => {
            return(
            <StorageUnitSelectableDisplay className={classes.sizeCard} key={unit.area} {...unit}/>
            )
        })
        
    }</Grid>
    </TabPanel>
    <TabPanel index={3} value={value}>
        {
        storageUnits.L.map((unit) => {
            return(
            <StorageUnitSelectableDisplay className={classes.sizeCard} key={unit.area} {...unit}/>
            )
        })
    }
    </TabPanel>
    {errorText?<FormHelperText error>{errorText.size}</FormHelperText>:''}
    </>
    )
}

export default SizeSelectionArea