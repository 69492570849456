import React, { Dispatch, SetStateAction, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

interface DatePickerProps {
  label: string,
  name: string,
  value: Date,
  onChange: Dispatch<SetStateAction<Date>>
}

const OrderDatePicker = (props: DatePickerProps) => {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        {...props}
        autoOk
        disablePast
        disableToolbar
        variant='inline'
        format='dd/MM/yyyy' />
    </MuiPickersUtilsProvider>
  );
}

export default OrderDatePicker